import React, {useEffect} from 'react';
import './App.css';
import Plot from 'react-plotly.js';
import {Measurement, WeatherStation} from './data_types';
import {API_URL} from "./config";
import Select from "react-dropdown-select";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Weather Dashboard</h1>
      </header>
        <body>
            <div className="humidity-plot">
                <StationView />
            </div>
        </body>
    </div>
  );
};

function StationView() {
    const [stations, setStations] = React.useState<WeatherStation[]>([])
    const [selectedStaton, setSelectedStation] = React.useState<{"label": string, "value": string}>({'label': '--------', 'value': 'unselected'})

    const handleChange = (value: {'label': string, 'value': string}[]) => {
        console.log("Value change to ", value)
        setSelectedStation(value[0])
    };

    useEffect(() => {
        fetch(API_URL + 'weather_station/')
          .then(res => res.json())
          .then(
            (result) => {
              setStations(result)
            },
            (error) => {
              // Todo: handle error
            }
          );
    }, [setStations]);

    let default_option = [
        {'label': '--------', 'value': 'unselected'},
    ]

    let options_1 = stations.map((station) => {
        return {
            "label": station.name + " in " + station.city + " (" + station.coordinates + ")",
            "value": station.uuid
        };
    })

    let options = default_option.concat(options_1);

    // console.log(options)
    console.log("Selectable options: ")
    console.log(options)

    if (selectedStaton['value'] !== 'unselected' || selectedStaton == null) {
        var end_time: Date = new Date(Date.now());
        var start_time: Date = new Date(end_time.getTime() - 86400 * 1000)

        return (
            <div>
              <Select options={options} values={[selectedStaton]} onChange={handleChange} />
              <WeatherDataPlots uuid={selectedStaton['value']} end_time={end_time} start_time={start_time}/>
            </div>
        )
    }
    else {
        return (
            <Select options={options} values={[selectedStaton]} onChange={handleChange} />
        )
    }
};

type StationProps = {
  uuid: string,
  start_time: Date,
  end_time: Date
};

function WeatherDataPlots({ uuid, start_time, end_time }: StationProps) {
    const [weatherData, setWeatherData] = React.useState<Measurement[]>([]);

    useEffect(() => {
        fetch(API_URL + 'station_measurements/' + uuid + '/?sampling_interval=60' + '&start_time=' + start_time.toISOString() + '&end_time=' + end_time.toISOString())
          .then(res => res.json())
          .then(
            (result) => {
              setWeatherData(result)
            },
            (error) => {
              // Todo: handle error
              console.log(error)
            }
          );
    }, [setWeatherData, uuid, start_time, end_time]);

    let time_stamps: string[] = weatherData.map((wd) => {return wd.time_stamp;});
    let humidity: number[] = weatherData.map((wd) => {return wd.humidity;});
    let temperature: number[] = weatherData.map((wd) => {return wd.air_temperature});
    let pressure: number[] = weatherData.map((wd) => {return wd.air_pressure});
    let time_stamps_date: Date[] = time_stamps.map((elem) => {return new Date(elem)})

    return (
      <div>
        <Plot
          data={[
            {
              x: time_stamps_date,
              y: humidity,
              type: 'scatter',
              mode: 'lines',
              marker: {color: 'darkseagreen'},
            },
          ]}
          layout={ {title: 'Humidity (relative)'} }
        />
        <Plot
          data={[
            {
              x: time_stamps_date,
              y: pressure,
              type: 'scatter',
              mode: 'lines',
              marker: {color: 'darkslateblue'},
            },
          ]}
          layout={ {title: 'Pressure [hPa]'} }
        />
        <Plot
          data={[
            {
              x: time_stamps_date,
              y: temperature,
              type: 'scatter',
              mode: 'lines',
              marker: {color: 'seagreen'},
            },
          ]}
          layout={ {title: 'Temperature [ºC]'} }
        />
      </div>

    );
};

export default App;
